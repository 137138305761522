<template>
   <div class="row m-1 px-1">
    <div class="col-xl-12 col-md-12">
      <div class="col-12 px-0 pb-1">
        <div class="card card-psh border m-half h-100">
          <div class="bg-light-primary rounded-top p-1">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 border-0">
                <p-icon name="bi-globe" size="36px" color="primary" />
              </div>
              <div class="d-flex flex-column">
                <h4 class="card-title mb-25">
                  New Web Release
                </h4>
                <p class="card-text mb-0">
                  Trigger web release reload warning
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <dx-util-validation-group>
              <div class="row">
                <div class="col-12 mb-1">
                  <p class="my-0">
                    To inform all web clients, please click the button below.
                  </p>
                </div>
              </div>
              <div class="form-row align-items-end">
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>&nbsp;</label>
                  <dx-util-button text="Trigger New Web Client Release" type="default"
                    styling-mode="contained" :element-attr="btnElementAttr"
                    @click="triggerRelease"
                  />
                </div>
              </div>
            </dx-util-validation-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import systemService from '@/http/requests/system/systemService'
import { Notify } from '@/@robustshell/utils'

export default {
  data() {
    return {
      versionNo: null,
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  methods: {
    triggerRelease(e) {
      systemService.triggerNewWebRelease().then(() => {
        Notify.success('New release triggered successfully.')
      }).catch(error => {
        Notify.error(`Trigger creation failed: ${error}`)
      })
    },

  },

}
</script>
